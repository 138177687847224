// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".TagList-module__ellipsesPill___dot0k{border-left:unset;padding-top:0px;padding-bottom:0px;border:1px solid rgba(0,0,0,0.1);background-color:rgba(0,0,0,0.06);font-size:0.8rem;margin:0;color:rgba(0,0,0,0.4)}.TagList-module__filterBubble___2oFvP{font-size:13px;padding:0.2rem 0.4rem;background-color:#E6E9FC;border-radius:20px;margin-bottom:5px;width:-webkit-max-content;width:-moz-max-content;width:max-content;padding:6px 12px 6px 12px !important;border:1px solid #98A3D9;border-radius:20px;margin-right:5px;margin-bottom:5px;position:relative}\n", "",{"version":3,"sources":["/home/siva/ch-job-marketplace/releases/20241112102302/app/javascript/components/common/TagList/styles/TagList.module.scss"],"names":[],"mappings":"AAAA,sCACE,iBAAkB,CAClB,eAAgB,CAChB,kBAAmB,CACnB,gCAAoC,CACpC,iCAAqC,CACrC,gBAAiB,CACjB,QAAS,CACT,qBAAyB,CAC1B,sCAGC,cAAe,CACf,qBAAsB,CACtB,wBAAyB,CACzB,kBAAmB,CACnB,iBAAkB,CAClB,yBAAkB,CAAlB,sBAAkB,CAAlB,iBAAkB,CAClB,oCAAqC,CACrC,wBAAyB,CACzB,kBAAmB,CACnB,gBAAiB,CACjB,iBAAkB,CAClB,iBAAkB","file":"TagList.module.scss","sourcesContent":[".ellipsesPill {\n  border-left: unset;\n  padding-top: 0px;\n  padding-bottom: 0px;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  background-color: rgba(0, 0, 0, 0.06);\n  font-size: 0.8rem;\n  margin: 0;\n  color: rgba(0, 0, 0, 0.4);\n}\n\n.filterBubble{\n  font-size: 13px;\n  padding: 0.2rem 0.4rem,;\n  background-color: #E6E9FC;\n  border-radius: 20px;\n  margin-bottom: 5px;\n  width: max-content;\n  padding: 6px 12px 6px 12px !important;\n  border: 1px solid #98A3D9;\n  border-radius: 20px;\n  margin-right: 5px;\n  margin-bottom: 5px;\n  position: relative;\n}"]}]);
// Exports
exports.locals = {
	"ellipsesPill": "TagList-module__ellipsesPill___dot0k",
	"filterBubble": "TagList-module__filterBubble___2oFvP"
};
module.exports = exports;
