// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".CandidateSkills-module__Skills___1Ppkv span{background:#EBEDFA;border-radius:20px;font-size:12px;padding:4px 13px 4px 13px;color:#768BFF;margin:5px 5px 5px 0px;display:inline-block}@media only screen and (min-device-width: 320px) and (max-device-width: 767px){.CandidateSkills-module__Skills___1Ppkv{text-align:center}}\n", "",{"version":3,"sources":["/home/siva/ch-job-marketplace/releases/20241112102302/app/javascript/components/common/CandidateSkills/styles/CandidateSkills.module.scss"],"names":[],"mappings":"AAAA,6CACI,kBAAmB,CACnB,kBAAmB,CACnB,cAAe,CACf,yBAA0B,CAC1B,aAAc,CACd,sBAAuB,CACvB,oBAAqB,CACxB,+EAGG,wCACI,iBAAkB,CACrB","file":"CandidateSkills.module.scss","sourcesContent":[".Skills span{\n    background: #EBEDFA;\n    border-radius: 20px;\n    font-size: 12px;\n    padding: 4px 13px 4px 13px;\n    color: #768BFF;\n    margin: 5px 5px 5px 0px;\n    display: inline-block;\n}\n\n@media only screen and (min-device-width : 320px) and (max-device-width : 767px){\n    .Skills{\n        text-align: center;\n    }\n}"]}]);
// Exports
exports.locals = {
	"Skills": "CandidateSkills-module__Skills___1Ppkv"
};
module.exports = exports;
