import React from 'react'
import styles from './styles/ProfileAvatar.module.scss'
import { Dropdown, Image, NavDropdown} from 'react-bootstrap';
import Util from '../../../utils/util'
const ProfileAvatarEmployer = ({profileAvatar, userId, organization_id, isEmployer, currentUser, userBelongsToCT, subOrgBelongsToCT, isTalent}) => {
  return(
    <>
    <NavDropdown title={(currentUser.first_name) ? currentUser.first_name+' '+ currentUser.last_name : currentUser.email } id="basic-nav-dropdown-task-icon" style={{marginLeft: '20px'}}>
        <NavDropdown.Item href={`/users/profile/${userId}`}
                          className={`navbarLinkCust linkMenu` }>View Profile</NavDropdown.Item>
        <NavDropdown.Item href="/communities" className={`navbarLinkCust linkMenu` }>My Community</NavDropdown.Item>
        <NavDropdown.Item href="/account/setting" className={`navbarLinkCust linkMenu` }>Account Settings</NavDropdown.Item>
        <NavDropdown.Item href="/account/invite_your_friend" className={`navbarLinkCust linkMenu` }>Invite Your Friend</NavDropdown.Item>
        {isEmployer &&  <NavDropdown.Item href={organization_id ? `/organizations/${organization_id}/careers` : '/complete/profile'} className={`navbarLinkCust linkMenu` }>Organization's Profile</NavDropdown.Item>}
        {(isEmployer || isTalent) && !(userBelongsToCT || subOrgBelongsToCT) &&
            <NavDropdown.Item href="/subscription" className={`navbarLinkCust linkMenu` }>Subscriptions & Billing</NavDropdown.Item>
        }
        <NavDropdown.Item onClick={signOut} className={`navbarLinkCust linkMenu` }>Log Out</NavDropdown.Item>
    </NavDropdown>
    {profileAvatar ?
        <Image src={profileAvatar} alt="Avatar" className={styles.avatar} />:
            <div className={styles.candidateAvtarImage}>{ (currentUser.first_name) ? (currentUser?.first_name && Util.getFirstCharater(currentUser?.first_name)) + (currentUser?.last_name && Util.getFirstCharater(currentUser?.last_name) ) : (currentUser?.email && Util.getFirstCharater(currentUser?.email) ) }</div>
    }
    </>
  )

  function signOut(){
    localStorage.removeItem("user")
    if(localStorage.getItem("meet-sourcer-showed")){
      localStorage.removeItem("meet-sourcer-showed")
    }
    fetch('/users/sign_out', {method: 'DELETE'})
    .then((res) => {
      window.location.href = '/users/sign_in'
    })
  }
}

export default ProfileAvatarEmployer;
