// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".AtMentionInput-module__menu___g-KgP{max-height:10rem;overflow:auto}\n", "",{"version":3,"sources":["/home/siva/ch-job-marketplace/releases/20241112102302/app/javascript/components/common/AtMentionInput/styles/AtMentionInput.module.scss"],"names":[],"mappings":"AAAA,qCACI,gBAAiB,CACjB,aAAc","file":"AtMentionInput.module.scss","sourcesContent":[".menu {\n    max-height: 10rem;\n    overflow: auto;\n}\n"]}]);
// Exports
exports.locals = {
	"menu": "AtMentionInput-module__menu___g-KgP"
};
module.exports = exports;
