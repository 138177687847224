import React, { useState, useEffect, useRef } from 'react'
import './../styles/communities.scss'
import { CKEditor } from 'ckeditor4-react';
import image3 from '../../../../../assets/v2/imgs/blog/blog-thumb-1.png'
// import avatarImg from '../../../../../assets/v2/imgs/avatar/ava_1.png'
import avatarImg from '../../../../../assets/images/placeholder.png'
import playImg from '../../../../../assets/v2/imgs/youtube.png'
import Util from '../../../../utils/util'
import Select from 'react-select'
import Modal from "react-bootstrap/Modal"
import { Row, Col } from 'react-bootstrap'
import Container from "react-bootstrap/Container"
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import CandidateAvatarIcon from '../../../common/CandidateAvatarIcon/CandidateAvatarIcon'
import Moment from 'moment'
import SweetAlert from 'react-bootstrap-sweetalert'
import AboutLeftPenal from '../Home/AboutLeftPenal'
import Paginator from '../../../common/Paginator/Paginator'
import MultiFileUpload from '../MultiFileUpload'
import { Spinner } from 'react-bootstrap'
import DocIcon from '../../../../../assets/v2/imgs/docicon.png'
import PdfIcon from '../../../../../assets/v2/imgs/pdficon.png'

const IndexPage = ({ communityId, categories, currentUser, avatar_url, post_id, is_community_member }) => {
  const categoryData = categories.map(category => ({
    label: category.name,
    value: category.id
  }));
  let visibleCategories, hideCategories;
  if (categories.length > 3) {
    visibleCategories = categories.slice(0, 3);
    hideCategories = categories.slice(3, categories.length);
  } else {
    visibleCategories = categories;
    hideCategories = [];
  }
  const [showMoreCategory, setShowMoreCategory] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(false)
  const [filterCategoryId, setFilterCategoryId] = useState('')
  const [myLikes, setmyLikes] = useState([])
  const [myCommentLikes, setmyCommentLikes] = useState([])
  const [defaultCategory, setDefaultCategory] = useState({})
  const [postComments, setPostComments] = useState([])
  const [postSubComments, setPostSubComments] = useState([])
  const [show, setShow] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const [method, setMethod] = useState('post')
  const [postDetail, setPostDetail] = useState({})
  const [commentDetail, setCommentDetail] = useState({})
  const [validationError, setValidationError] = useState({})
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [description, setDescription] = useState('');
  const [comments, setComments] = useState('');
  const [commentEdit, setCommentEdit] = useState('');
  const [communityPostDatas, setCommunityPostDatas] = useState([])
  const [activePage, setActivePage] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [totalCategory, setTotalCategory] = useState(0)
  const [currentCounts, setCurrentCounts] = useState(0)
  const [startRecord, setStartRecord] = useState(0)
  const [loading, setLoading] = useState(true)
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
  const [showCommentRemoveConfirmation, setShowCommentRemoveConfirmation] = useState(false)
  const [editorKey, setEditorKey] = useState(1)
  const [commentEditMode, setCommentEditMode] = useState('')
  const [subCommentEditMode, setSubCommentEditMode] = useState('')
  const [filterValue, setFilterValue] = useState('none')
  const [sortValue, setSortValue] = useState('newest')
  const [myPins, setmyPins] = useState([])
  const [multiFiles, setMultiFiles] = useState({})
  const [deleteFileConfiem, setDeleteFileConfiem] = useState(false)
  const [deleteFileId, setDeleteFileId] = useState(false)
  const [attechedFileCount, setAttechedFileCount] = useState(0)
  const [groupJoinAlert, setGroupJoinAlert] = useState('')
  const [subCommentId, setSubCommentId] = useState(0)
  const [showSubComment, setShowSubComment] = useState(false)
  const [showSubCommentId, setShowSubCommentId] = useState(0)
  const [subComment, setSubComment] = useState('')
  const [subCommentDetail, setSubCommentDetail] = useState(0)
  const [showSubCommentRemoveConfirmation,setShowSubCommentRemoveConfirmation] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const onHide = () => {
    setCommentEditMode('')
    setPostDetail({})
    setAttechedFileCount(0)
    setMethod('post')
    setShow(false)
  }
  const onHideDetail = () => {
    setCommentEditMode('')
    setPostDetail({})
    setAttechedFileCount(0)
    setShowDetail(false)
  }
  const handleChange = (e) => {
    setValidationError({})
  }
  const validateForm = (e) => {
    let errors = {}
    let formIsValid = true
    if (!e?.target?.title?.value?.trim()) {
      formIsValid = false;
      errors["title"] = "Title is required field."
    }
    if (selectedCategory === '') {
      formIsValid = false;
      errors["community_post_category_id"] = "Category is required field."
    }
    if (!description.trim()) {
      formIsValid = false;
      errors["description"] = "Description is required field."
    }
    setValidationError(errors)
    // setBtnDisabled(formIsValid)
    return formIsValid;
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (validateForm(e)) {
      setBtnDisabled(true)
      let URL = `/communities/${communityId}/post/create`
      if (method === 'put') {
        URL = `/communities/${communityId}/post/${postDetail.id}`
      }
      const payload = new FormData()
      payload.append('post[title]', e.target.title.value)
      payload.append('post[video_url]', e?.target?.video_url?.value || '')
      payload.append('post[description]', description)
      payload.append('post[community_post_category_id]', selectedCategory)
      payload.append('post[community_id]', communityId)
      // Append each file individually to the FormData
      multiFiles.forEach((file) => {
        payload.append('post[community_post_files][]', file.file);
      });
      //headers
      const CSRF_Token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
      const headers = {
        headers: {
          'content-type': 'multipart/form-data',
          'X-CSRF-Token': CSRF_Token,
        },
      }
      makeRequest(URL, method, payload, headers, {
        contentType: 'multipart/form-data',
        loadingMessage: 'Submitting...',
      }).then((res) => {
        if (res.data.success) {
          fetchData()
          onHide()
        }
      })
      // setBtnDisabled(false)
    }
  }
  const fetchData = () => {
    setBtnDisabled(true)
    const url = '/communities/' + communityId + '/post'
    makeRequest(url, 'get', { params: { page: (activePage + 1), category_id: filterCategoryId, sortValue: sortValue, filterValue: filterValue, post_id: post_id, searchValue: searchValue } }, {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setCommunityPostDatas(
          res.data.posts.map((post) => ({
            id: post.id,
            title: post?.title,
            description: post?.description,
            community_id: post?.community_id,
            created_at: post?.created_at,
            created_by: post?.created_by,
            community_post_category_id: post?.community_post_category_id,
            category: categoryMap.get(post.community_post_category_id) || 'Unknown',
            image_url: post?.user?.person?.avatar_url,
            video_url: post?.video_url,
            total_likes: post?.total_likes,
            total_comments: post?.total_comments,
            first_name: post?.user?.first_name,
            last_name: post?.user?.last_name,
            posted_name: post?.user ? Util.fullName(post.user?.first_name, post.user?.last_name) : '',
            community_files: post?.community_post_files,
          }))
        )
        setmyLikes(res.data.likes)
        setmyCommentLikes(res.data.comment_likes)
        setmyPins(res.data.post_pins)
        setPageCount(res.data.total_pages)
        setTotalCategory(res.data.total_count)
        setCurrentCounts(res.data.current_counts)
        setStartRecord(activePage * res.data.per_page)
        setBtnDisabled(false)
      },
    })
  }
  const categoryMap = new Map(categoryData.map(category => [category.value, category.label]));

  const showPostDetail = (post_id) => {
    console.log(communityPostDatas)
    const detail = communityPostDatas.find(post => post.id === post_id);
    setPostDetail(detail);
    setShowDetail(true);
    fetchCommentData(post_id);
    setAttechedFileCount((detail?.community_files)?.length || 0)
  }
  useEffect(() => {
    fetchData()
  }, [activePage, filterCategoryId, filterValue, sortValue])

  const videoIfream = (videoUrl) => {
    return (
      <a href={videoUrl} target='_blank'>
        <img alt="playImg" src={playImg} />
      </a>
    );
  }
  const editPost = (post_id) => {
    onHideDetail();
    const detail = communityPostDatas.find(post => post.id === post_id);
    const category_id = categoryData.find(cd => cd.value === detail?.community_post_category_id);
    setPostDetail(detail);
    setAttechedFileCount((detail?.community_files)?.length || 0)
    setDescription(detail?.description)
    setSelectedCategory(detail.community_post_category_id)
    setDefaultCategory(category_id)
    setShow(true)
    setMethod('put')
  }
  const deletePost = (post_id) => {
    // const detail = postDetail.find(post => post.id === post_id);
    // setPostDetail(detail);
    setShowRemoveConfirmation(true);
  }
  const deleteComment = (comment_id) => {
    const detail = postComments.find(comment => comment.id === comment_id);
    setCommentDetail(detail);
    setShowCommentRemoveConfirmation(true);
  }

  const deleteSubComment = (comment_id) => {
    setSubCommentDetail(comment_id);
    setShowSubCommentRemoveConfirmation(true);
  }
  
  const handleDelete = (e) => {
    const url = `/communities/${communityId}/post/${postDetail.id}`
    makeRequest(url, 'delete', '', {
      createSuccessMessage: () => 'Post deleted successfully',
      createErrorMessage: (e) => e.response.data.msg,
      onSuccess: () => {
        setShowRemoveConfirmation(false)
        onHideDetail();
        fetchData()
      },
    })
  }

  const onPinned = (id) => {
    if (checkCommunityMember()) {
      const url = `/communities/${communityId}/pin_post/${id}`
      makeRequest(url, 'get', '', {
        createSuccessMessage: () => 'Post pin successfully',
        createErrorMessage: (e) => e.response.data.msg,
        onSuccess: () => {
          setTimeout(() => {
            fetchData()
          }, 3000)
        },
      })
    }
  }

  const onUnPinned = (id) => {
    if (checkCommunityMember()) {
      const url = `/communities/${communityId}/unpin_post/${id}`
      makeRequest(url, 'get', '', {
        createSuccessMessage: () => 'Post Un-pinned Successfully',
        createErrorMessage: (e) => e.response.data.msg,
        onSuccess: () => {
          setTimeout(() => {
            fetchData()
          }, 3000)
        },
      })
    }
  }

  const handleLike = (post_id, action) => {
    if (checkCommunityMember()) {
      setBtnDisabled(true)
      URL = `/communities/${communityId}/post/${post_id}/${action}`
      const payload = new FormData()
      payload.append('post_like[community_post_id]', post_id)
      payload.append('post_like[community_id]', communityId)
      //headers
      const CSRF_Token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
      const headers = {
        headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': CSRF_Token,
        },
      }
      makeRequest(URL, method, payload, headers, {
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
      }).then((res) => {
        if (res.data.success) {
          const updatedData = communityPostDatas.map(communityPostData =>
            communityPostData.id === post_id ? { ...communityPostData, total_likes: res.data.total_likes } : communityPostData
          );
          setPostDetail({ ...postDetail, total_likes: res.data.total_likes });
          setCommunityPostDatas(updatedData);
          if (action == 'like') {
            setmyLikes(prevArray => [...prevArray, post_id]);
          } else {
            setmyLikes(prevArray => prevArray.filter(item => item !== post_id));
          }
        }
      })
      setBtnDisabled(false)
    }
  }
  const handleSubmitComment = (post_id) => {
    if (checkCommunityMember()) {
      setValidationError({})
      if (!comments.trim()) {
        let errors = {}
        errors["comment"] = "Comment is required field."
        setValidationError(errors)
        return false
      } else {
        setBtnDisabled(true)
        URL = `/communities/${communityId}/post/${post_id}/comment`
        const payload = new FormData()
        payload.append('post_comment[community_post_id]', post_id)
        payload.append('post_comment[community_id]', communityId)
        payload.append('post_comment[description]', comments)
        //headers
        const CSRF_Token = document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute('content')
        const headers = {
          headers: {
            'content-type': 'application/json',
            'X-CSRF-Token': CSRF_Token,
          },
        }
        makeRequest(URL, method, payload, headers, {
          contentType: 'application/json',
          loadingMessage: 'Submitting...',
        }).then((res) => {
          if (res.data.success) {
            setComments('');
            const updatedData = communityPostDatas.map(communityPostData =>
              communityPostData.id === post_id ? { ...communityPostData, total_comments: res.data.total_comments } : communityPostData
            );
            setPostDetail({ ...postDetail, total_comments: res.data.total_comments });
            setCommunityPostDatas(updatedData);
            fetchCommentData(post_id);
            // onHide()
          }
        })
      }
      setBtnDisabled(false)
    }
  }
  const handleSubmitSubComment = (post_id,comment_id) => {
    if (checkCommunityMember()) {
      setValidationError({})
      if (!subComment.trim()) {
        let errors = {}
        errors["sub_comment"] = "Comment is required field."
        setValidationError(errors)
        return false
      } else {
        setBtnDisabled(true)
        URL = `/communities/${communityId}/post/${post_id}/sub_comment/${comment_id}`
        const payload = new FormData()
        payload.append('post_sub_comment[community_comment_id]', comment_id)
        payload.append('post_sub_comment[community_post_id]', post_id)
        payload.append('post_sub_comment[community_id]', communityId)
        payload.append('post_sub_comment[description]', subComment)
        //headers
        const CSRF_Token = document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute('content')
        const headers = {
          headers: {
            'content-type': 'application/json',
            'X-CSRF-Token': CSRF_Token,
          },
        }
        makeRequest(URL, method, payload, headers, {
          contentType: 'application/json',
          loadingMessage: 'Submitting...',
        }).then((res) => {
          if (res.data.success) {
            setSubComment('');
            setShowSubComment(false);
            setShowSubCommentId(0);
            fetchCommentData(post_id);
          }
        })
      }
      setBtnDisabled(false)
    }
  }

  const handleSubmitSubCommentUpdate = (post_id,comment_id) => {
    if (checkCommunityMember()) {
      setValidationError({})
      if (!subComment.trim()) {
        let errors = {}
        errors["sub_comment"] = "Comment is required field."
        setValidationError(errors)
        return false
      } else {
        setBtnDisabled(true)
        URL = `/communities/${communityId}/post/${post_id}/sub_comment/${comment_id}/replay/${subCommentId}`
        const payload = new FormData()
        payload.append('post_sub_comment[community_comment_id]', comment_id)
        payload.append('post_sub_comment[community_post_id]', post_id)
        payload.append('post_sub_comment[community_id]', communityId)
        payload.append('post_sub_comment[description]', subComment)
        //headers
        const CSRF_Token = document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute('content')
        const headers = {
          headers: {
            'content-type': 'application/json',
            'X-CSRF-Token': CSRF_Token,
          },
        }
        makeRequest(URL, method, payload, headers, {
          contentType: 'application/json',
          loadingMessage: 'Submitting...',
        }).then((res) => {
          if (res.data.success) {
            setSubComment('');
            setShowSubComment(false);
            setShowSubCommentId(0);
            fetchCommentData(post_id);
            setSubCommentId(0)
          }
        })
      }
      setBtnDisabled(false)
    }
  }

  const fetchCommentData = (post_id) => {
    setLoading(true)
    setCommentEditMode('')
    URL = `/communities/${communityId}/post/${post_id}/comment`
    makeRequest(URL, 'get', { params: { community_post_id: post_id, community_Id: communityId } }, {
      contentType: 'application/json',
      loadingMessage: 'Fetching...',
      createSuccessMessage: (response) => response.data.message,
      onSuccess: (res) => {
        setPostComments(
          res.data.post_comments.map((post_comment) => ({
            id: post_comment.id,
            description: post_comment?.description,
            community_id: post_comment?.community_id,
            created_at: post_comment?.created_at,
            created_by: post_comment?.created_by,
            community_post_id: post_comment?.community_post_id,
            total_likes: post_comment?.total_likes,
            total_comments: post_comment?.total_comments,
            // image_url: post?.user?.person?.avatar_url || '',
            first_name: post_comment?.user?.first_name,
            last_name: post_comment?.user?.last_name,
            posted_name: post_comment?.user ? Util.fullName(post_comment.user?.first_name, post_comment.user?.last_name) : ''
          }))
        )
        setPostSubComments(res.data.post_sub_comments)
        console.log(res.data.post_sub_comments)
        setLoading(false)
      },
    })
  }
  const handleCommentDelete = (e) => {
    const url = `/communities/${communityId}/post/${commentDetail.community_post_id}/comment/${commentDetail.id}`
    makeRequest(url, 'delete', '', {
      createSuccessMessage: () => 'Comment deleted successfully',
      createErrorMessage: (e) => e.response.data.msg,
      onSuccess: (res) => {
        setShowCommentRemoveConfirmation(false)
        fetchCommentData(commentDetail.community_post_id);
        const updatedData = communityPostDatas.map(communityPostData =>
          communityPostData.id === commentDetail.community_post_id ? { ...communityPostData, total_comments: res.data.total_comments } : communityPostData
        );
        // setPostDetail({ ...postDetail, total_comments: res.data.total_comments });
        setCommunityPostDatas(updatedData);
      },
    })
  }
  const handleSubCommentDelete = (e) => {
    const url = `/communities/${communityId}/post/${postDetail?.id}/sub_comment/${subCommentDetail}`
    makeRequest(url, 'delete', '', {
      createSuccessMessage: () => 'Comment deleted successfully',
      createErrorMessage: (e) => e.response.data.msg,
      onSuccess: (res) => {
        setShowSubCommentRemoveConfirmation(false)
        setSubCommentDetail(0)
        fetchCommentData(postDetail?.id);
      },
    })
  }
  const handleCommentEdit = (post_comment) => {
    setValidationError({})
    if (!commentEdit.trim()) {
      let errors = {}
      errors["commentEdit"] = "Comment is required field."
      setValidationError(errors)
      return false
    } else {
      setBtnDisabled(true)
      URL = `/communities/${communityId}/post/${post_comment.community_post_id}/comment/${post_comment.id}`
      const payload = new FormData()
      payload.append('post_comment[description]', commentEdit)
      //headers
      const CSRF_Token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
      const headers = {
        headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': CSRF_Token,
        },
      }
      makeRequest(URL, 'PUT', payload, headers, {
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
      }).then((res) => {
        if (res.data.success) {
          setCommentEdit('');
          setCommentEditMode('')
          const updatedData = postComments.map(postComment =>
            postComment.id === post_comment.id ? { ...postComment, description: res.data.post_comment.description } : postComment
          );
          setPostComments(updatedData);
        }
      })
    }
    setBtnDisabled(false)
  }
  const handleRemoveFile = (e) => {
    const url = `/communities/post/file/${deleteFileId}`
    makeRequest(url, 'delete', '', {
      createSuccessMessage: () => 'File deleted successfully',
      createErrorMessage: (e) => e.response.data.msg,
      onSuccess: (res) => {
        const updatedCommunityPostDatas = communityPostDatas.map(post => {
          if (post.id === postDetail.id) {
            const updatedCommunityFiles = post.community_files.filter(file => file.id !== deleteFileId);
            setPostDetail({ ...postDetail, community_files: updatedCommunityFiles });
            return { ...post, community_files: updatedCommunityFiles };
          }
          return post;
        });
        setAttechedFileCount((updatedCommunityPostDatas?.community_files)?.length || 0)
        setCommunityPostDatas(updatedCommunityPostDatas);
        setDeleteFileId('')
        setDeleteFileConfiem(false);
      },
    })
  }
  const onFilterClick = (type) => {
    setFilterValue(type)
  }
  const onSortClick = (type) => {
    setSortValue(type)
  }

  const filePreviewsFream = (preview, index, type) => {
    return (
      <div className="col-lg-3 col-md-3" key={index}>
        {(type == 'remove') ?
          <div key={index} className="file-preview">
            <i
              onClick={() => {
                setDeleteFileId(preview.id)
                setDeleteFileConfiem(true)
              }} className="fi fi-rr-trash upload_file_remove"></i>
            {previewsFream(preview)}
          </div>
          :
          <a href={preview.file_url} target='_black'>
            <div key={index} className="file-preview">
              <i className="fi fi-rr-download upload_file_download"></i>
              {previewsFream(preview)}
            </div>
          </a>
        }
      </div>
    );
  }
  const previewsFream = (preview) => {
    return (
      preview.file_type &&
      (() => {
        switch (preview.file_type) {
          case 'application/pdf':
          case 'application/x-pdf': // Additional MIME type for PDF
          case 'application/vnd.pdf': // Additional MIME type for PDF
            return (
              <img title={preview.name} src={PdfIcon} alt={preview.name} />
            );
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          case 'application/vnd.ms-word.document.macroenabled.12': // Additional MIME type for DOCM (macro-enabled DOCX)
            return (
              <img title={preview.name} src={DocIcon} alt={preview.name} />
            );
          default:
            return <img title={preview.name} src={preview.file_url} alt={preview.name} />;
        }
      })()
    )
  }
  const copyToClipboard = (id) => {
    var url = window.location.origin + "/communities/" + communityId + "?p_id=" + id
    navigator.clipboard.writeText(url)
      .then(() => {

      })
      .catch((error) => {
        console.error('Unable to copy to clipboard: ', error);
      });
  }
  function removeHTMLTags(htmlString) {
    return htmlString.replace(/<[^>]*>/g, '');
  }

  function checkCommunityMember() {
    if (!is_community_member) {
      setGroupJoinAlert('true')
      return false
    } else if (is_community_member.status == 'pending') {
      setGroupJoinAlert('pending')
    } else {
      return true
    }
  }

  function openPostModel() {
    if (checkCommunityMember()) {
      setPostDetail({})
      setMethod('post')
      setShow(true)
    }
  }
  // Function to trigger the button click event
  const triggerButtonClick = () => {
    if (buttonRef.current) {
      setGroupJoinAlert('')
      buttonRef.current.click(); // Trigger the button click event
    }
  };
  const buttonRef = useRef(null);
  const handleSubmitCommentChild = (obj, action) => {
      console.log(obj,action)
      setBtnDisabled(true)
      URL = `/communities/${communityId}/post/${obj.community_post_id}/comment/${obj.id}/${action}`
      //headers
      const CSRF_Token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
      const headers = {
        headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': CSRF_Token,
        },
      }
      makeRequest(URL, 'post', {}, headers, {
        contentType: 'application/json',
        loadingMessage: 'Submitting...',
      }).then((res) => {
        if (res.data.success) {
          const updatedData = postComments.map(communityPostData =>
            communityPostData.id === obj.id ? { ...communityPostData, total_likes: res.data.total_likes } : communityPostData
          );
          setPostComments(updatedData);
          if (action == 'like') {
            setmyCommentLikes(prevArray => [...prevArray, obj.id]);
          } else {
            setmyCommentLikes(prevArray => prevArray.filter(item => item !== obj.id));
          }
        }
      })
      setBtnDisabled(false)
  }

  const subCommentShow = (p_c_id) => {
      setShowSubComment(true)
      setShowSubCommentId(p_c_id)
  }

  const onHideDetailSubComment = () => {
    setShowSubComment(false)
    setShowSubCommentId(0)
  }

  const editCommentSub = (sub_com_id,com_id,description) => {
    setShowSubCommentId(com_id)
    setSubComment(description)
    setShowSubComment(true)
    setSubCommentId(sub_com_id)
  }

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    fetchData();
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <>
      <div className="container communities-detail">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12 p-0 m-0">
            <div className="row" style={{marginLeft: '2%'}}>
              <div className="col-lg-9 col-md-9 col-sm-9 mb-20 pl-0">
                  <div class="search-form-1">
                    <form onSubmit={ (e) => handleSearchSubmit(e)}>
                      <input type="text" value={searchValue} onChange={handleSearchChange} placeholder="Search for questions & answers" style={{width: '100%', height: '50px'}}/>
                      <button type="submit" style={{marginRight: '25px'}}><i class="fi-rr-search"></i></button>
                    </form>
                  </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 pl-0">
                  <button className='btn btn-primary mt-0 post-add-btn' onClick={() => {openPostModel()}}> <i class="fi-rr-plus post-add-btn-icon"></i> Post</button>
              </div>
            </div>
            {/* <div className="col-lg-12 col-md-12 col-sm-12 mb-20 pl-0">
              <div className="keep-reading" style={{ width: '98%' }}>
                <button onClick={() => {
                  openPostModel()
                }} className="btn btn-border form-control"><i className="fi-rr-edit mr-10"></i> Write something</button>
              </div>
            </div> */}
            <div className="col-lg-12">
              <div className="row ml-1">
                <div className="col-lg-9 col-md-9 col-sm-9 pl-0 m-0 skill-header">
                  <a href="#" onClick={() => { setFilterCategoryId('') }} className={`btn btn-border btn-sm mr-10 ${filterCategoryId === '' ? 'active' : ''}`}>All</a>

                  {visibleCategories.map((category, index) => (
                    <>
                      <a key={index} onClick={() => { setFilterCategoryId(category.id) }} href="#" className={`btn btn-border btn-sm mr-10 ${filterCategoryId === category.id ? 'active' : ''}`}>{category.name}</a>
                    </>
                  ))}
                  {showMoreCategory && (
                    <>
                      {hideCategories.map((category, index) => (
                        <a key={index} href="#" onClick={() => { setFilterCategoryId(category.id) }} className={`btn btn-border btn-sm mr-10 ${filterCategoryId === category.id ? 'active' : ''}`}>{category.name}</a>
                      ))}
                      <a onClick={() => setShowMoreCategory(false)} className="btn btn-border btn-sm mr-10">Less...</a>
                    </>
                  )}
                  {(!showMoreCategory && categories.length > 3) && (
                    <a onClick={() => setShowMoreCategory(true)} className="btn btn-border btn-sm mr-10">More...</a>
                  )}
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3">
                  {post_id == 0 &&
                    <>
                      <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-parimary  btn-sm m-0 mr-10" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="fi-rr-filter"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-light" style={{ margin: '0px' }} >
                          <li><span className="filter-item mb-2"><b style={{ fontWeight: '800' }}>Filters</b></span></li>
                          <li className='pl-15'> <span className="dropdown-item" onClick={(e) => onFilterClick('none')} ><input className='filter-radio' type="radio" name="post_filter" checked={filterValue === 'none'} /> None</span> </li>
                          <li className='pl-15'><span className="dropdown-item" onClick={(e) => onFilterClick('pinned')}><input className='filter-radio' type="radio" name="post_filter" checked={filterValue === 'pinned'} />  Pinned </span></li>
                          <li className='pl-15'> <span className="dropdown-item" onClick={(e) => onFilterClick('no-commen')}><input className='filter-radio' type="radio" name="post_filter" checked={filterValue === 'no-commen'} /> No comments</span></li>
                        </div>
                        <button type="button" className="btn btn-parimary  btn-sm m-0" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="fi-rr-arrow-up"></i><i className="fi-rr-arrow-down"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-light" style={{ margin: '0px' }} >
                          <li><span className="filter-item mb-2"><b style={{ fontWeight: '800' }}>Sort</b></span></li>
                          <li className='pl-15'><span className="dropdown-item  mb-2" onClick={(e) => onSortClick('newest')}><input className='filter-radio' type="radio" name="post_sort" checked={sortValue === 'newest'} /> Newest post</span></li>
                          <li className='pl-15'><span className="dropdown-item  mb-2" onClick={(e) => onSortClick('oldest')}><input className='filter-radio' type="radio" name="post_sort" checked={sortValue === 'oldest'} /> Oldest post </span></li>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>


            <div className="post-listing">
              {communityPostDatas && communityPostDatas.map((communityPostData, index) => {
                return (<div className="author-bio posts p-20 mt-20 border-radius-15 bg-white m-3">
                  <div>
                    <div className='d-flex justify-content-between'>
                      <div className="author-image-custom mb-15">
                        <a href="#">{communityPostData?.image_url ?
                          <img src={communityPostData?.image_url || avatarImg} alt={communityPostData?.posted_name} className="avatar" /> : <CandidateAvatarIcon firstName={communityPostData?.first_name} lastName={communityPostData?.last_name} /> } </a>
                        <div className="author-infor" onClick={() => { showPostDetail(communityPostData?.id) }}>
                          <h5 className="mb-5 custom-autor-detail">{communityPostData?.posted_name}</h5>
                          <p className="mb-0 text-muted font-xs">
                            <span className="mr-5">{Moment(communityPostData?.created_at).fromNow()} in</span>
                            <span className="has-dot">{communityPostData?.category}</span>
                          </p>
                        </div>
                      </div>
                      <div className="eNqYVN kVYybR">
                        {post_id == 0 &&
                          <>
                            <button type="button" className="cngPsE mt-1" data-bs-toggle="dropdown" aria-expanded="false">
                              <div className="filter-icon-custom" style={{ padding: '2px' }}>
                                <svg viewBox="0 0 40 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M30 5C30 3.67392 30.5268 2.40215 31.4645 1.46447C32.4021 0.526785 33.6739 0 35 0C36.3261 0 37.5979 0.526785 38.5355 1.46447C39.4732 2.40215 40 3.67392 40 5C40 6.32608 39.4732 7.59785 38.5355 8.53553C37.5979 9.47321 36.3261 10 35 10C33.6739 10 32.4021 9.47321 31.4645 8.53553C30.5268 7.59785 30 6.32608 30 5ZM15 5C15 3.67392 15.5268 2.40215 16.4645 1.46447C17.4021 0.526785 18.6739 0 20 0C21.3261 0 22.5979 0.526785 23.5355 1.46447C24.4732 2.40215 25 3.67392 25 5C25 6.32608 24.4732 7.59785 23.5355 8.53553C22.5979 9.47321 21.3261 10 20 10C18.6739 10 17.4021 9.47321 16.4645 8.53553C15.5268 7.59785 15 6.32608 15 5ZM0 5C0 3.67392 0.526784 2.40215 1.46447 1.46447C2.40215 0.526785 3.67392 0 5 0C6.32608 0 7.59785 0.526785 8.53553 1.46447C9.47322 2.40215 10 3.67392 10 5C10 6.32608 9.47322 7.59785 8.53553 8.53553C7.59785 9.47321 6.32608 10 5 10C3.67392 10 2.40215 9.47321 1.46447 8.53553C0.526784 7.59785 0 6.32608 0 5V5Z"></path></svg>
                              </div>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-light" style={{ margin: '0px' }} >
                              {post_id == 0 &&
                                <>
                                  <li><a className="dropdown-item" href="#" onClick={() => { myPins?.includes(communityPostData?.id) ? onUnPinned(communityPostData?.id) : onPinned(communityPostData?.id) }}> {myPins?.includes(communityPostData?.id) ? 'Un-Pinned' : 'Pin'}</a></li>
                                  <li><a className="dropdown-item" onClick={() => copyToClipboard(communityPostData?.id)} href="#"  >Copy link</a></li>
                                </>
                              }
                            </ul>
                          </>
                        }
                      </div>
                    </div>
                    <div className="author-des">
                      <div className='row' onClick={() => { showPostDetail(communityPostData?.id) }}>
                        <div className="col-lg-10 col-md-10">
                          <label className="post-title custom-post-title">{communityPostData?.title}</label>
                          <div className='short_desc' dangerouslySetInnerHTML={{ __html: removeHTMLTags(communityPostData.description) }} />
                        </div>
                        {(!communityPostData?.community_files || communityPostData?.community_files.length == 0) && communityPostData?.video_url &&
                          <div className="col-lg-2 col-md-2">
                            {videoIfream(communityPostData?.video_url)}
                          </div>
                        }
                        {(communityPostData?.community_files && communityPostData?.community_files.length > 0) && (
                          <div className="col-lg-2 col-md-2">
                            <div className="file-preview-2">
                              {previewsFream(communityPostData.community_files[0])}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 text-muted">
                    <button onClick={() => handleLike(communityPostData?.id, myLikes?.includes(communityPostData?.id) ? 'unlike' : 'like')} className={`btn ${myLikes?.includes(communityPostData?.id) ? 'btn-warning' : 'btn-border'}  btn-sm m-0`}><i className="fi fi-rr-thumbs-up mr-10"></i>{communityPostData?.total_likes}</button>
                    <button onClick={() => { showPostDetail(communityPostData?.id) }} className={`btn btn-border btn-sm m-0 ml-10`}><i className="fi fi-rr-comment mr-10"></i>{communityPostData?.total_comments}</button>
                  </div>
                </div>
                )
              })}
            </div>
            {(pageCount > 1) &&
              <div className="paginations">
                <Paginator
                  pageCount={pageCount}
                  activePage={activePage}
                  setActivePage={setActivePage}
                  pageWindowSize={10}
                />
              </div>
            }

          </div>
          <div className="col-md-4">
            <AboutLeftPenal communityId={communityId} buttonRef={buttonRef}></AboutLeftPenal>
          </div>
        </div>
      </div>
      <Modal
        onHide={onHide}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className='modal-entry-form'
      >
        <Modal.Header closeButton className='mt-2 mb-2'>
          <Modal.Title id="contained-modal-title-vcenter" >
            <div className={"user-account"}>
              <img src={(avatar_url) ? avatar_url : avatarImg} alt="CardinalTalent" />
              <div className={"content"}>
                <h6 className={"user-name"}><span className={"text-brand"}>{(currentUser.first_name) ? (currentUser?.first_name + ' ' + currentUser?.last_name) : currentUser?.email}</span> posting in GroupName</h6>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <form onSubmit={(e) => { handleSubmit(e) }} style={{ width: "100%" }}>
              <Row className="w-100">
                <Col xs={12} sm={12} lg={6} md={6}>
                  <span>Select Category* : </span>
                  <Select
                    options={categoryData}
                    name='community_post_category_id'
                    id='community_post_category_id'
                    placeholder={'Select Category'}
                    defaultValue={defaultCategory}
                    onChange={(event) => setSelectedCategory(event?.value || '')}
                    menuPosition='fixed'
                  />
                  <label className='error'>
                    {validationError && validationError.community_post_category_id}
                  </label>
                </Col>
                <Col xs={12} sm={6} lg={6}>
                  <span>Title* : </span>
                  <input type='text' defaultValue={postDetail?.title || ''} onChange={handleChange} className='form-control w-100' name='title' id="title" />
                  <small className='error'>
                    {validationError && validationError.title}
                  </small>
                </Col>
                <Col xs={12} sm={12} lg={12} className='mt-10'>
                  <span>Description* : </span>
                  <CKEditor
                    initData={postDetail?.description || ''}
                    config={{
                      versionCheck: false,
                      toolbar: [
                        ['Bold'],
                        ['Link', 'Unlink'],
                        ['NumberedList', 'BulletedList'],
                      ]
                    }
                    }
                    key={editorKey}
                    onChange={(content) =>
                      setDescription(content.editor.getData())
                    }
                  />
                  {/* <textarea defaultValue={postDetail.description} onChange={handleChange} className='form-control w-100' name='description' id="description" /> */}
                  <small className='error'>
                    {validationError && validationError.description}
                  </small>
                </Col>
                <Col xs={12} sm={12} lg={12} className='mt-10'>
                  <span >Video Link : </span>
                  <input type='text' defaultValue={postDetail?.video_url || ''} onChange={handleChange} className='form-control w-100' name='video_url' id="video_url" />
                  <small className='error'>
                    {validationError && validationError.video_url}
                  </small>
                </Col>
                <Col xs={12} sm={12} lg={12} className='mt-10'>
                  <div className='row mt-10'>
                    {postDetail?.community_files && postDetail?.community_files.map((communityFiles, index) => {
                      return filePreviewsFream(communityFiles, index, 'remove')
                    })}

                    <MultiFileUpload setMultiFiles={setMultiFiles} attechedFileCount={attechedFileCount} setAttechedFileCount={setAttechedFileCount}></MultiFileUpload>
                  </div>
                </Col>
              </Row>
              <Row className="w-100 modal-entry-form-row mt-20">
                <Col xs={12} sm={12} lg={12}>
                  <button type='submit' disabled={btnDisabled} className='btn btn-default btn-radius m-0 mt-10'>
                    {btnDisabled ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      method === 'put' ? 'Update' : 'Save'
                    )}
                  </button>
                  <button onClick={() => {
                    onHide()
                  }} type='button' disabled={btnDisabled} className='btn btn-danger btn-radius m-0 mt-10 ml-10'>
                    {btnDisabled ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      'Cancel'
                    )}

                  </button>
                </Col>
              </Row>
            </form>
          </Container>
        </Modal.Body>
      </Modal>

      <Modal
        onHide={onHideDetail}
        show={showDetail}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className='modal-entry-form postdetail'
      >
        <Modal.Header className='mt-2 mb-2'>
          <div className='modal-title' id="contained-modal-title-vcenter" >
            <div className="author-bio">
              <div className="author-image">
                <a href="#"><img src={postDetail?.image_url || avatarImg} alt={postDetail?.posted_name} className="avatar" /></a>
                <div className="author-infor">
                  <h5 className="mb-5">{postDetail?.posted_name}</h5>
                  <p className="mb-0 text-muted font-xs">
                    <span className="mr-5">{Moment(postDetail?.created_at).fromNow()} in</span>
                    <span className="has-dot">{postDetail?.category}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            {currentUser.id == postDetail?.created_by && (
              <>
                <button onClick={() => { editPost(postDetail?.id) }} className="mb-0 mt-0 btn-small background-blue-light mr-5">EDIT</button>
                <button onClick={() => { deletePost(postDetail?.id) }} className="mb-0 mt-0  btn-small background-urgent btn-pink mr-5">DELETE</button>
              </>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="author-des">
              <h5 className="post-title">{postDetail?.title}</h5>
              <p className='mt-10'>
                <div dangerouslySetInnerHTML={{ __html: postDetail?.description }} />
              </p>
              <div className='row mt-10'>
                {postDetail?.video_url &&
                  <div className="col-lg-3 col-md-3">
                    {postDetail?.video_url && videoIfream(postDetail?.video_url)}
                  </div>
                }
                {postDetail?.community_files && postDetail?.community_files.map((communityFiles, index) => {
                  return filePreviewsFream(communityFiles, index, 'view')
                })}
              </div>
            </div>
            <div className="mt-10 text-muted">
              <button onClick={() => handleLike(postDetail?.id, myLikes?.includes(postDetail?.id) ? 'unlike' : 'like')} className={`btn ${myLikes?.includes(postDetail?.id) ? 'btn-warning' : 'btn-border'}  btn-sm mb-0 ml-0`}><i className="fi fi-rr-thumbs-up mr-10"></i>{postDetail?.total_likes}</button>
              <button onClick={() => { showPostDetail(postDetail?.id) }} className={`btn btn-border btn-sm mb-0`}><i className="fi fi-rr-comment mr-10"></i>{postDetail?.total_comments}</button>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {postComments && postComments?.map((postComment, index) => {
            return (<div className="author-bio comment p-10 mb-10 community_sub_custom">
              <div className="author-image justify-content-between mb-10">
                <div className="author-infor">
                  <p className="mb-0 text-muted font-xs">
                    <span className="mr-10 commentName"><b>{postComment?.posted_name}</b></span>
                    <span className="has-dot">{Moment(postComment?.created_at).fromNow()}</span>
                  </p>
                </div>
                {currentUser.id == postComment?.created_by && (
                  <div className="text-muted">
                    <button className='m-0 p-0' onClick={() => { setCommentEditMode(index) }}><i className="fi fi-rr-edit mr-10"></i></button>
                    <button className='m-0 p-0' onClick={() => { deleteComment(postComment?.id) }}><i className="fi fi-rr-trash"></i></button>
                  </div>
                )}
              </div>
              <div className="author-des">
                {currentUser.id == postComment?.created_by && commentEditMode === index ? (
                  <>
                    <textarea
                      defaultValue={postComment?.description}
                      onChange={(event) => setCommentEdit(event.target.value)}
                      className='form-control w-100'
                    />
                    <label className='error'>
                      {validationError && validationError.commentEdit}
                    </label>
                    <div className="text-muted mt-10 text-end">
                      <button disabled={btnDisabled} onClick={() => handleCommentEdit(postComment)} className={`btn btn-border btn-sm m-0`}>Save</button>
                      <button onClick={() => { setCommentEditMode('') }} className={`btn btn-border btn-sm ml-10 m-0`}>Cancel</button>
                    </div>
                  </>
                ) : (
                  <p>{postComment?.description}</p>
                )}
              </div>
              <div className="mt-10 text-muted">
                    <button  className={`btn ${myCommentLikes?.includes(postComment?.id) ? 'btn-warning' : 'btn-border'}  btn-sm mb-0 ml-0 `}  onClick={() => handleSubmitCommentChild(postComment,`${myCommentLikes?.includes(postComment?.id) ?  'unlike' : 'like'}`)}  ><i className="fi fi-rr-thumbs-up mr-10"></i>{ postComment?.total_likes}</button>
                    <button  className={`btn btn-border btn-sm mb-0`} onClick={()=>subCommentShow(postComment?.id) }><i className="fi fi-rr-comment mr-10"></i> { postComment?.total_comments}</button>
              </div>
              {postSubComments[postComment?.id] && postSubComments[postComment?.id]?.map((postSubComment, index) => {
                  return (<div className="author-bio comment p-10 mt-10 comment_sub">
                    <div className="author-image justify-content-between mb-10">
                      <div className="author-infor">
                        <p className="mb-0 text-muted font-xs">
                          <span className="mr-10 commentName"><b>{ postSubComment?.user ? Util.fullName(postSubComment.user?.first_name, postSubComment.user?.last_name) : ''}</b></span>
                          <span className="has-dot">{Moment(postSubComment?.created_at).fromNow()}</span>
                        </p>
                      </div>
                      {currentUser.id == postSubComment?.created_by && (
                        <div className="text-muted">
                          <button className='m-0 p-0' onClick={() => { editCommentSub(postSubComment?.id,postComment?.id,postSubComment?.description) }}><i className="fi fi-rr-edit mr-10"></i></button>
                          <button className='m-0 p-0' onClick={() => { deleteSubComment(postSubComment?.id) }}><i className="fi fi-rr-trash"></i></button>
                        </div>
                      )}
                    </div>
                    <div className="author-des">
                      {currentUser.id == postSubComment?.created_by && commentEditMode === index ? (
                        <>
                          <textarea
                            defaultValue={postSubComment?.description}
                            onChange={(event) => setCommentEdit(event.target.value)}
                            className='form-control w-100'
                          />
                          <label className='error'>
                            {validationError && validationError.commentEdit}
                          </label>
                          <div className="text-muted mt-10 text-end">
                            <button disabled={btnDisabled} onClick={() => handleCommentEdit(postComment)} className={`btn btn-border btn-sm m-0`}>Save</button>
                            <button onClick={() => { setCommentEditMode('') }} className={`btn btn-border btn-sm ml-10 m-0`}>Cancel</button>
                          </div>
                        </>
                      ) : (
                        <p>{postSubComment?.description}</p>
                      )}
                    </div>
                </div>
                )
              })
              }
              { showSubComment == true && showSubCommentId == postComment?.id &&
                <div className='mt-15 ml-15 mr-25 mb-10'>
                    <textarea value={subComment} placeholder='Your Reply'  onChange={(event) => setSubComment(event.target.value)} className='form-control w-100' name='sub_comment' id="sub_comment" />
                      <label className='error'>
                          {validationError && validationError.sub_comment}
                      </label>
                    <div className="text-muted mt-10" style={{ float: 'right'}}>
                      {
                        subCommentId == 0 ?   <button disabled={btnDisabled} onClick={() => handleSubmitSubComment(postDetail?.id,postComment?.id)} className={`btn btn-primary btn-sm m-0`} style={{backgroundColor: '#2e6ef5'}}>Save</button> :  <button disabled={btnDisabled} onClick={() => handleSubmitSubCommentUpdate(postDetail?.id,postComment?.id)} className={`btn btn-primary btn-sm m-0`} style={{backgroundColor: '#2e6ef5'}}>Update</button>
                      }
                     
                      <button onClick={() => { onHideDetailSubComment() }} className={`btn btn-border btn-sm ml-10 m-0`}>Cancel</button>
                    </div>
                </div>
              }
            </div>)
          })}
          <textarea value={comments} onChange={(event) => setComments(event.target.value)} placeholder='Your Comment' className='form-control w-100' name='comment' id="comment" />
          <label className='error'>
            {validationError && validationError.comments}
          </label>
          {comments.trim() != '' && (
            <div className="text-muted">
              <button disabled={btnDisabled} onClick={() => handleSubmitComment(postDetail?.id)} className={`btn btn-primary btn-sm m-0`}>Save</button>
              <button onClick={() => { onHideDetail() }} className={`btn btn-border btn-sm ml-10 m-0`}>Cancel</button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
      {
        showRemoveConfirmation &&
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure you want to delete post?"
          onConfirm={() => (handleDelete())}
          onCancel={() => setShowRemoveConfirmation(false)}
          focusCancelBtn
        >
          You will not be able to recover this post!
        </SweetAlert>
      }
      {
        showCommentRemoveConfirmation &&
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure you want to delete comment?"
          onConfirm={() => (handleCommentDelete())}
          onCancel={() => setShowCommentRemoveConfirmation(false)}
          focusCancelBtn
        >
          You will not be able to recover this comment!
        </SweetAlert>
      }
      { showSubCommentRemoveConfirmation &&
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure you want to delete comment?"
          onConfirm={() => (handleSubCommentDelete())}
          onCancel={() => setShowSubCommentRemoveConfirmation(false)}
          focusCancelBtn
        >
          You will not be able to recover this comment!
        </SweetAlert>
      }
      {
        deleteFileConfiem &&
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure you want to delete this file?"
          onConfirm={() => (handleRemoveFile())}
          onCancel={() => {
            setDeleteFileConfiem(false)
            setDeleteFileId('')
          }}
          focusCancelBtn
        >
          You will not be able to recover this comment!
        </SweetAlert>
      }

      {groupJoinAlert == 'true' &&
        <SweetAlert title="Join Group"
          confirmBtnText="Join Group"
          cancelBtnText="Cancel"
          onConfirm={(e) => { triggerButtonClick() }}
          onCancel={() => {
            setGroupJoinAlert(''); // Close the SweetAlert modal
          }}>
          to like, post, and comment in The 4D Copywriting Community
        </SweetAlert>
      }

      {groupJoinAlert == 'pending' &&
        <SweetAlert title="Membership Pending"
          confirmBtnText="Membership Pending"
          cancelBtnText="Cancel"
          onConfirm={(e) => { setGroupJoinAlert('') }}
          onCancel={() => {
            setGroupJoinAlert(''); // Close the SweetAlert modal
          }}>
          Waiting for admin approval
        </SweetAlert>
      }
    </>
  )
}
export default IndexPage